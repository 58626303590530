import Tool from "components/Tool";

export default function Tools({ tools, tests, samples, showDeleteToolModal }) {
    return (
        <div className="instrument-container">
            {tools.map((tool) => (
                <Tool
                    key={tool.key}
                    tool={tool}
                    tests={tests}
                    samples={samples}
                    showDeleteToolModal={showDeleteToolModal}
                />
            ))}
        </div>
    );
}
