import { useEffect, useState } from "react";

import { Input, Modal } from "antd";
import { checkLogin, getDiagnoses, getTests } from "api";
import { getUniqueKey, showError } from "common";
import Samples from "components/Samples";
import Tools from "components/Tools";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;

export default function ResearchPage() {
    const dateFormat = "DD.MM.YYYY";
    const sendingFormat = "YYYY-MM-DD";

    const anyGenderString = "ANY";
    const maleGenderString = "м";
    const femaleGenderString = "ж";

    const [tests, setTests] = useState([]);
    const [diagnoses, setDiagnoses] = useState([]);

    const [samples, setSamples] = useState([]); // Список выборок (групп пациентов)
    const [tools, setTools] = useState([]); // Список статистических инструментов

    const [sampleCount, setSampleCount] = useState(0);
    const [toolCount, setToolCount] = useState(0);

    const navigate = useNavigate();

    function processDiagnosesResponse(response) {
        const data = response.data;

        const d = data.map((d) => {
            const group = d.count != d.group_count && d.group_count != 0 ? `, сам диагноз ${d.group_count})` : "";
            const code = d.code === "" ? "" : `${d.code} — `;
            return {
                id: d.id,
                pId: d.parent_id,
                label: `${code}${d.name} (${d.count}${group})`,
                value: String(d.id),
            };
        });

        setDiagnoses(d);
    }

    function processTestResponse(response) {
        const data = response.data;

        const oakValues = data.filter((test) => test.analysis_name == "Общий анализ крови");
        const bakValues = data.filter((test) => test.analysis_name == "Биохимический анализ крови");

        const converTestToSelectOptions = (array) =>
            array.map((test) => {
                return {
                    label: `${test.name} [${test.count}]`,
                    value: test.id,
                    self: test,
                };
            });

        const t = [
            {
                label: "Общий анализ крови",
                options: converTestToSelectOptions(oakValues),
            },
            {
                label: "Биохимический анализ крови",
                options: converTestToSelectOptions(bakValues),
            },
        ];

        setTests(t);
    }

    function loadData() {
        // Такое объединение загрузок позволяет убедиться, что все необходимые данные были получены к моменту
        // создания выборки и инструмента
        Promise.all([getDiagnoses(), getTests()])
            .then(function (responses) {
                processDiagnosesResponse(responses[0]);
                processTestResponse(responses[1]);

                addSample();
                addTool();
            })
            .catch((error) => {
                showError(error);
            });
    }

    function getSearchedSamples() {
        // search should be based on index, diagnosis, and district
        // diagnosis search should be by name and/or mkb code but idk how to do that so :shrug:
        var input_ = document.getElementById("sample-search-input");
        if (input_ != null) {
            var filter_ = input_.value;
            var samples_ = document.getElementsByClassName("sample");
            for (var i = 0; i < samples_.length; i++) {
                if (
                    filter_ == "" ||
                    samples_[i].innerText.split("\n")[0].toLowerCase().includes(filter_.toLowerCase())
                ) {
                    samples_[i].style.display = "";
                } else {
                    samples_[i].style.display = "none";
                }
            }
        }
    }

    function addSample() {
        const newSample = {
            index: sampleCount + 1,
            key: getUniqueKey(),
            activated: true,
            name: `Выборка №${sampleCount + 1}`,
        };
        setSampleCount(sampleCount + 1);
        setSamples([...samples, newSample]);
    }

    function renameSample(index, newName) {
        if (newName !== "") {
            samples.find((item) => item.index === index).name = newName;
            setSamples([...samples]);
        }
    }

    function showRenameSampleModal(index) {
        let oldName = samples.find((item) => item.index === index).name;
        confirm({
            title: "Изменение названия выборки",
            content: (
                <div>
                    <p>Введите новое название для выборки "{oldName}":</p>
                    <Input id="rename-sample-input" size="small" defaultValue={oldName} />
                </div>
            ),
            okText: "ОК",
            cancelText: "Отмена",
            onOk() {
                renameSample(index, document.getElementById("rename-sample-input").value);
            },
        });
    }

    function deleteSample(index) {
        setSamples(samples.filter((item) => item.index !== index));
    }

    function showDeleteSampleModal(index) {
        confirm({
            title: "Удаление выборки",
            content: `Вы действительно хотите удалить выборку "${samples.find((item) => item.index === index).name}"?`,
            okText: "ОК",
            cancelText: "Отмена",
            onOk() {
                deleteSample(index);
            },
        });
    }

    function addTool() {
        const newTool = {
            index: toolCount + 1,
            key: getUniqueKey(),
            activated: true,
        };
        setToolCount(toolCount + 1);
        setTools([...tools, newTool]);
    }

    function deleteTool(index) {
        setTools(tools.filter((item) => item.index !== index));
    }

    function showDeleteToolModal(index) {
        confirm({
            title: "Удаление иследования",
            content: `Вы действительно хотите удалить исследование №${index}?`,
            onOk() {
                deleteTool(index);
            },
        });
    }

    useEffect(() => {
        checkLogin()
            .then((response) => {
                console.log("logined");

                loadData();
            })
            .catch((error) => {
                navigate("/login");
            });
    }, []);

    return (
        <div>
            <div className="app-sidebar">
                <input
                    className="sample-search"
                    type="text"
                    id="sample-search-input"
                    onChange={getSearchedSamples}
                    placeholder="Поиск выборки..."
                />

                <button className="create-bttn" onClick={addSample}>
                    + Создать новую выборку
                </button>

                <Samples
                    samples={samples}
                    diagnoses={diagnoses}
                    showRenameSampleModal={showRenameSampleModal}
                    showDeleteSampleModal={showDeleteSampleModal}
                />
            </div>

            <div className="app-main">
                <button className="create-bttn" onClick={addTool}>
                    + Провести новое исследование
                </button>

                <Tools tools={tools} tests={tests} samples={samples} showDeleteToolModal={showDeleteToolModal} />
            </div>
        </div>
    );
}
