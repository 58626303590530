import Sample from "components/Sample";

export default function Samples({ samples, diagnoses, showRenameSampleModal, showDeleteSampleModal }) {
    return (
        <div className="sample-container">
            {samples.map((sample) => (
                <Sample
                    key={sample.key}
                    sample={sample}
                    diagnoses={diagnoses}
                    showRenameSampleModal={showRenameSampleModal}
                    showDeleteSampleModal={showDeleteSampleModal}
                />
            ))}
        </div>
    );
}
