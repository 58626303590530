import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function DocumentationPage() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/research");
    }, []);

    return (
        <div>
            {/* <div>Doc</div>
            <div>Doc</div>
            <div>Doc</div>
            <div>Doc</div>
            <div>Doc</div>
            <div>Doc</div>
            <div>Doc</div>
            <div>Doc</div> */}
        </div>
    );
}
