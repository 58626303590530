import { useEffect, useState } from "react";

import { getUniqueKey, showError } from "common";
import DocumentationPage from "pages/DocumentationPage";
import LoginPage from "pages/LoginPage";
import MainPage from "pages/MainPage";
import ResearchPage from "pages/ResearchPage";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

const routes = [
    {
        path: "/",
        component: <MainPage />,
    },
    {
        path: "/research",
        component: <ResearchPage />,
    },
    {
        path: "/documentation",
        component: <DocumentationPage />,
    },
    {
        path: "/login",
        component: <LoginPage />,
    },
];

export default function App() {
    const [currentRoute, setCurrentRoute] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div>
            <div className="app-header">
                <div className="icon-and-title">
                    <img className="icon" src="icon.ico" />
                    <div className="title">StatMedLab</div>
                </div>

                <div className="links">
                    <a href="/">Главная страница</a>
                    <a href="/research">Проведение исследований</a>
                    <a href="/documentation">Документация</a>
                    <a href="/login">Вход</a>
                </div>
            </div>

            <Routes>
                {routes.map((route) => (
                    <Route path={route.path} element={route.component} key={getUniqueKey()} />
                ))}
            </Routes>
        </div>
    );
}
