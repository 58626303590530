import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_DOMAIN + "/api/",
    withCredentials: true
});

export function login(credentials) {
    return instance({ method: "post", url: "login", data: credentials});
}

export function checkLogin() {
    return instance({ method: "get", url: "check-login" });
}

export function getTests() {
    return instance({ method: "get", url: "tests" });
}

export function getDiagnoses() {
    return instance({ method: "get", url: "diagnoses" });
}

export function excecuteTool(toolName, toolParams) {
    return instance({ method: "post", url: toolName, data: toolParams });
}